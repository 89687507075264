
@import '../../scss/variables';

$avatar-size: 2.5rem;
.avatar {
  display: inline-block;
  width: $avatar-size;
  height: $avatar-size;
  line-height: $avatar-size;
  text-align: center;  
  border-radius:50%;
  font-size: 15px;
  color: white;

  &-user {
    background: $primary-colour;
  }

  &-group {
    background: $secondary-colour;
  }
}