.PageLayout {
  display: flex;

  .PageContent {
    padding: 1.25rem 2.25rem;
    margin: 0 0 4rem 6.875rem;
  }
}


.ui.green.label.environment {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000;
}