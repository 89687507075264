@import "../../../../scss/variables";

.vrm {
  border-radius: 2px;
  border: solid 1px $grey-light;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  color: $black;
  width: 109px;
  height: 26px;
  padding-top: 6px;
}

.ui.table.basic tbody tr.permit-row td.table-tools .ui.button {
  top: 13px
}
