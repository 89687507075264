@import "../../../../scss/variables";

.user-status {
  position: relative;
  text-indent: 15px;
  text-transform: capitalize;
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 36%;
    left: 10px;
    border-radius: 50%;
  }

  &.status-active::before {
    background: $status--active;
  }
  &.status-pending::before {
    background: $status--pending;
  }
  &.status-inactive::before, &.status-expired::before {
    background: $status--inactive;
  }
}

.user-email {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $secondary-colour;
  transition: $fast all ease;

  &:hover {
    color: $primary-colour;
  }
}
