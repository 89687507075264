///////
//
// Root size
//
///////

$root-size : 16px;

///////
//
// Colours
//
///////

$black: #000;
$white: #fff;
$grey-dark: #222222;
$grey-mid: #888;
$grey-light: #6a6a6a;
$grey-very-light: #cdcdcd;
$grey-super-light: #e9e9e9;

$primary-colour        : #2c0826;
$secondary-colour      : #cb9942;
$background-colour     : $white;
$text-primary-colour   : $black;
$text-secondary-colour : #828282;
$trans-white-10pc      : rgba($white, 0.1);
$error-text-colour     : #f26678;
$error-colour          : #d0021b;


$status--active: #81d135;
$status--pending: #f3a536;
$status--inactive: #ce0b24;

$interstital-text-colour      : $background-colour;
$interstital-primary-colour   : $secondary-colour;
$interstital-secondary-colour : $background-colour;

$form-detail: $grey-super-light;

$input-bg            : $trans-white-10pc;
$background-gradient : linear-gradient(125deg, #57134e, $primary-colour);

$secondary-colour--disabled: #E5CCA0;

///////
//
// Touch sizing
//
///////

$touch-size    : 3rem;
$touch-size-sm : 1.25rem;

///////
//
// Touch sizing
//
///////

$button-size--round-large: 2.25rem;

///////
//
// Touch sizing
//
///////

$interstital-width : 20rem;
$interstital-top : 7rem;

///////
//
// Spacing
//
///////

$m-bottom   : 1.5rem;
$lr-padding : 1.5rem;

///////
//
// Styling
//
///////

$border-radius : .125rem;


///////
//
// Typography
//
///////

$default-font              : 'Lato';
$input-font-size           : .875rem;
$input-font-size-large     : 1.75rem;
$label-font-size           : .6875rem;
$label-letter-spacing      : .125rem;
$link-lable-text-transform : uppercase;

///////
//
// Forms
//
///////

$field-margin-sides: .5rem;
$field-margin: 0 $field-margin-sides;

$label-padding-vertical: .5rem;
$label-padding: $label-padding-vertical 0;

$border-width: 0.125rem;

$input-height: 2.5rem;
$input-height-large: 3.5rem;

$input-padding-side: .625rem;
$input-padding: $input-padding-side 0;

$input-border-bottom-width: .125rem;
$input-border-bottom: $input-border-bottom-width solid $form-detail;
$input-border-bottom-width--interstital : .1875rem;
$input-border-bottom--interstital: solid $input-border-bottom-width--interstital transparent;

$input-focus-border-width : 0.1875rem;

///////
//
// Transitions
//
///////

$fast : 200ms;
$normal: 400ms;
$slow: 500ms;
