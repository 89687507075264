@import url(https://fonts.googleapis.com/css?family=Lato);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.ui.modal {
  width: 41.25rem; }
  .ui.modal > .ui.button--close {
    position: absolute;
    top: -1.125rem;
    right: -1.125rem;
    width: 2.25rem;
    height: 2.25rem;
    background: #cdcdcd;
    border-radius: 1.125rem; }
  .ui.modal .content {
    padding: 3.5rem 6rem 1.5rem; }

.\--position-absolute {
  position: absolute; }

.\--full-width {
  width: 100%;
  clear: both; }

.\--half-width {
  width: 50%; }

.\--float-left, .ui.form .field.\--full-width, .ui.form .field.\--half-width, .ui.form .field.\--quarter-width,
.ui.form .field > .selection.dropdown.\--full-width,
.ui.form .field > .selection.dropdown.\--half-width,
.ui.form .field > .selection.dropdown.\--quarter-width,
.ui.form aside.\--full-width,
.ui.form aside.\--half-width,
.ui.form aside.\--quarter-width {
  float: left; }

.\--spacer {
  min-height: 2rem; }

aside {
  padding: 0 0 1.5rem;
  font-size: 0.6875rem;
  color: #6a6a6a; }

.ui.form .field.error {
  margin-bottom: 0; }
  .ui.form .field.error .ui.input, .ui.form .field.error .ui.button {
    margin-bottom: 0; }

.ui.form .field.\--full-width, .ui.form .field.\--half-width, .ui.form .field.\--quarter-width,
.ui.form .field > .selection.dropdown.\--full-width,
.ui.form .field > .selection.dropdown.\--half-width,
.ui.form .field > .selection.dropdown.\--quarter-width,
.ui.form aside.\--full-width,
.ui.form aside.\--half-width,
.ui.form aside.\--quarter-width {
  clear: none;
  margin: 0 0.5rem;
  width: calc(100% - 1rem); }

.ui.form .field.\--half-width,
.ui.form .field > .selection.dropdown.\--half-width,
.ui.form aside.\--half-width {
  width: calc(50% - 1rem); }

.ui.form .field.\--quarter-width,
.ui.form .field > .selection.dropdown.\--quarter-width,
.ui.form aside.\--quarter-width {
  width: calc(25% - 1rem); }
  .ui.form .field.\--quarter-width .ui.icon.input > input,
  .ui.form .field > .selection.dropdown.\--quarter-width .ui.icon.input > input,
  .ui.form aside.\--quarter-width .ui.icon.input > input {
    padding-right: 0 !important; }

.ui.form .field.\--large input,
.ui.form .field.\--large input[type='email'],
.ui.form .field.\--large input[type='number'],
.ui.form .field.\--large input[type='password'],
.ui.form .field.\--large input[type='search'],
.ui.form .field.\--large input[type='tel'],
.ui.form .field.\--large input[type='time'],
.ui.form .field.\--large input[type='text'],
.ui.form .field.\--large input[type='file'],
.ui.form .field.\--large input[type='url'],
.ui.form .field > .selection.dropdown.\--large input,
.ui.form .field > .selection.dropdown.\--large input[type='email'],
.ui.form .field > .selection.dropdown.\--large input[type='number'],
.ui.form .field > .selection.dropdown.\--large input[type='password'],
.ui.form .field > .selection.dropdown.\--large input[type='search'],
.ui.form .field > .selection.dropdown.\--large input[type='tel'],
.ui.form .field > .selection.dropdown.\--large input[type='time'],
.ui.form .field > .selection.dropdown.\--large input[type='text'],
.ui.form .field > .selection.dropdown.\--large input[type='file'],
.ui.form .field > .selection.dropdown.\--large input[type='url'],
.ui.form aside.\--large input,
.ui.form aside.\--large input[type='email'],
.ui.form aside.\--large input[type='number'],
.ui.form aside.\--large input[type='password'],
.ui.form aside.\--large input[type='search'],
.ui.form aside.\--large input[type='tel'],
.ui.form aside.\--large input[type='time'],
.ui.form aside.\--large input[type='text'],
.ui.form aside.\--large input[type='file'],
.ui.form aside.\--large input[type='url'] {
  height: 3.5rem;
  padding: 0 0 0.5rem;
  font-size: 1.75rem; }

.ui.form .field > label,
.ui.form .field .labeled .label,
.ui.form .field > .selection.dropdown > label,
.ui.form .field > .selection.dropdown .labeled .label,
.ui.form aside > label,
.ui.form aside .labeled .label {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  background: transparent;
  color: #888;
  font-size: 0.6875rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase; }

.ui.form .field .labeled,
.ui.form .field > .selection.dropdown .labeled,
.ui.form aside .labeled {
  display: block;
  width: 100%; }
  .ui.form .field .labeled.error > label,
  .ui.form .field .labeled.error .label,
  .ui.form .field > .selection.dropdown .labeled.error > label,
  .ui.form .field > .selection.dropdown .labeled.error .label,
  .ui.form aside .labeled.error > label,
  .ui.form aside .labeled.error .label {
    color: #ce0b24;
    opacity: .5; }

.ui.form .field .ui.input input,
.ui.form .field > .selection.dropdown .ui.input input,
.ui.form aside .ui.input input {
  width: 100%; }

.ui .ui.input.\--left-pad-large input,
.ui .ui.input.\--left-pad-large input[type='email'],
.ui .ui.input.\--left-pad-large input[type='number'],
.ui .ui.input.\--left-pad-large input[type='password'],
.ui .ui.input.\--left-pad-large input[type='search'],
.ui .ui.input.\--left-pad-large input[type='tel'],
.ui .ui.input.\--left-pad-large input[type='time'],
.ui .ui.input.\--left-pad-large input[type='text'],
.ui .ui.input.\--left-pad-large input[type='file'],
.ui .ui.input.\--left-pad-large input[type='url'] {
  padding-left: 2.5rem; }

.ui.button.primary.button {
  width: 100%;
  height: 3rem;
  border: 0;
  border-radius: 1.5rem;
  background: #2c0826;
  font-size: 0.6875rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase; }

.ui.button.button--icon {
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border-color: transparent;
  padding: 0; }
  .ui.button.button--icon > .icon,
  .ui.button.button--icon > .icon:not(.button):not(.dropdown) {
    margin: 0 auto;
    color: #2c0826; }

.ui.input, .ui.button {
  margin-bottom: 1.5rem; }

.ui.input input::placeholder {
  color: #888; }

.ui.input input,
.ui.input input[type='email'],
.ui.input input[type='number'],
.ui.input input[type='password'],
.ui.input input[type='search'],
.ui.input input[type='tel'],
.ui.input input[type='time'],
.ui.input input[type='text'],
.ui.input input[type='file'],
.ui.input input[type='url'] {
  border: none;
  border-bottom: 0.125rem solid #e9e9e9;
  box-shadow: none;
  color: #222222;
  padding: 0.625rem 0;
  border-radius: 0; }
  .ui.input input:focus,
  .ui.input input[type='email']:focus,
  .ui.input input[type='number']:focus,
  .ui.input input[type='password']:focus,
  .ui.input input[type='search']:focus,
  .ui.input input[type='tel']:focus,
  .ui.input input[type='time']:focus,
  .ui.input input[type='text']:focus,
  .ui.input input[type='file']:focus,
  .ui.input input[type='url']:focus {
    border-bottom-color: #2c0826; }

.ui.input.\--no-bottom-border input {
  border-bottom: none; }

.ui.error {
  margin-bottom: 0; }
  .ui.error input,
  .ui.error input[type="email"],
  .ui.error input[type="number"],
  .ui.error input[type="password"],
  .ui.error input[type="search"],
  .ui.error input[type="tel"],
  .ui.error input[type="time"],
  .ui.error input[type="text"],
  .ui.error input[type="file"],
  .ui.error input[type="url"] {
    color: #ce0b24;
    border-color: #ce0b24; }

.ui.selection.dropdown {
  padding: 0.625rem 0;
  border: none;
  border-bottom: 0.125rem solid #e9e9e9; }
  .ui.selection.dropdown.\--no-border-bottom {
    border-bottom: none; }

.ui.toggle.checkbox {
  margin: 0.25rem 0;
  overflow: visible; }
  .ui.toggle.checkbox input ~ label {
    font-size: 0.6875rem;
    letter-spacing: 0.125rem;
    padding-left: 3.25rem;
    color: inherit;
    text-transform: uppercase; }
    .ui.toggle.checkbox input ~ label:before {
      height: 1rem;
      width: 2.5rem;
      margin: 0.125rem;
      border-radius: 0.5rem;
      background-color: rgba(255, 255, 255, 0.1); }
    .ui.toggle.checkbox input ~ label:after {
      height: 1.25rem;
      width: 1.25rem; }
  .ui.toggle.checkbox input:focus ~ label:before, .ui.toggle.checkbox input:checked ~ label:before, .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: rgba(255, 255, 255, 0.1) !important; }
  .ui.toggle.checkbox input:checked ~ label:after {
    left: 1.5rem; }

.authentication.interstital .input,
.authentication.interstital input,
.authentication.interstital button {
  color: #fff; }

.authentication.interstital .ui.input {
  width: 100%; }

.authentication.interstital .ui input {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: solid 0.1875rem transparent transparent;
  border-radius: 0.125rem;
  height: 3rem;
  padding: 0.1875rem 1.5rem 0px;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.875rem; }
  .authentication.interstital .ui input:focus {
    background: rgba(255, 255, 255, 0.1);
    border-color: #cb9942; }

.authentication.interstital .ui.primary.button {
  background: #cb9942; }

.field__validation-messages li {
  height: 1.5rem;
  color: #ce0b24;
  font-size: .625rem;
  line-height: 1.75rem; }

label[for="edit-permit__vrm"] {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  background: transparent;
  color: #888;
  font-size: 0.6875rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  float: left;
  margin: 0 0.5rem; }

.form__vrm-fieldset {
  margin: 0 0.5rem;
  margin-bottom: 1.5rem;
  border: solid 0.125rem #e9e9e9;
  border-radius: 0.25rem; }
  .form__vrm-fieldset .ui.input {
    margin-bottom: 0; }
    .form__vrm-fieldset .ui.input > input {
      text-align: center;
      padding: 1rem 0 0; }
      .form__vrm-fieldset .ui.input > input[name="vrm"] {
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0.375rem; }
      .form__vrm-fieldset .ui.input > input[name="vrmDescription"] {
        padding-top: 0;
        margin-bottom: 1rem;
        color: #6a6a6a;
        font-size: .625rem; }

#edit-group__permitType-field {
  z-index: 99;
  top: 0;
  right: 0;
  margin-top: 0.5rem;
  margin-right: 0; }
  #edit-group__permitType-field .dropdown {
    min-width: calc(25% - 1rem); }

.ui.selection.dropdown .menu {
  margin-top: .1875rem; }

.modals .ui.checkbutton {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #cb9942;
  padding: .22rem .9rem;
  border-radius: 1rem;
  margin: .5rem .5rem 0 0; }
  .modals .ui.checkbutton input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .modals .ui.checkbutton label {
    font-size: 14px;
    padding: 2px 5px; }
    .modals .ui.checkbutton label::before, .modals .ui.checkbutton label::after {
      display: none; }
  .modals .ui.checkbutton.checked {
    border: 1px solid #cb9942;
    background: #cb9942; }
    .modals .ui.checkbutton.checked label,
    .modals .ui.checkbutton.checked label:focus {
      color: #fff; }

.checkbutton {
  display: inline-block;
  border: 1px solid #E5CCA0;
  border-radius: 1rem;
  margin: .5rem .5rem 0 0;
  padding: .26rem 1rem;
  font-size: 14px; }
  .checkbutton.checkbutton-active {
    border: 1px solid #E5CCA0;
    background: #E5CCA0;
    color: #fff; }
  .checkbutton.checkbutton-inactive {
    border: 1px solid #e9e9e9;
    background: #e9e9e9;
    color: #888; }

.ui.\--border-thin {
  border: 1px solid; }

.ui.table.basic {
  font-family: Lato;
  border: 0;
  margin-bottom: 1rem; }
  .ui.table.basic thead > tr > th {
    border: 0;
    text-transform: uppercase;
    font-family: Lato;
    font-size: .625rem;
    opacity: .5;
    letter-spacing: .1125rem;
    color: #000;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal; }
  .ui.table.basic tbody tr {
    border: 0; }
    .ui.table.basic tbody tr td {
      padding: .75rem;
      border: 0;
      border-bottom: solid 2px #e2e2e2;
      font-size: .75rem; }
      .ui.table.basic tbody tr td.no-bottom-border {
        border-bottom: 0; }
      .ui.table.basic tbody tr td.larger-font {
        font-size: .875rem;
        line-height: 1rem; }
      .ui.table.basic tbody tr td.shrunk-to-fit {
        width: 1%;
        white-space: nowrap; }
      .ui.table.basic tbody tr td.table-tools {
        padding-right: 0;
        width: 6.25rem; }
        .ui.table.basic tbody tr td.table-tools .ui.button {
          margin-bottom: 0;
          top: 1.5rem;
          position: relative;
          float: right; }
        .ui.table.basic tbody tr td.table-tools a {
          position: relative;
          top: 0.75rem;
          display: block;
          float: right;
          margin: 0 0 0 .125rem; }
          .ui.table.basic tbody tr td.table-tools a.add-link {
            top: .625rem; }
      .ui.table.basic tbody tr td.align-middle {
        vertical-align: middle; }
  .ui.table.basic tbody .button--delete {
    position: absolute; }

.table__top-buttons {
  text-align: right;
  padding-right: 1rem; }

.status {
  position: absolute;
  width: .625rem;
  height: .625rem;
  border-radius: 50%; }
  .status.status-active {
    background: #81d135; }
  .status.status-pending {
    background: #f3a536; }
  .status.status-inactive, .status.status-expired {
    background: #ce0b24; }
  .status-text {
    margin-left: 1rem; }

.tabletools--modal-trigger.ui.button.button--icon {
  width: auto;
  float: right;
  margin-right: 0;
  text-transform: uppercase;
  font-size: .75rem; }
  .tabletools--modal-trigger.ui.button.button--icon ~ .button--icon {
    margin-right: 2rem; }
  .tabletools--modal-trigger.ui.button.button--icon .add-icon {
    margin-top: 15px; }
  .tabletools--modal-trigger.ui.button.button--icon span {
    margin-top: .75rem;
    margin-left: .75rem;
    display: block;
    float: right;
    font-size: .75rem;
    line-height: 2.55;
    letter-spacing: .09rem;
    color: #000; }

.group-table.ui.table.basic tbody > tr > td.table__cell--details {
  padding-top: 1.5rem;
  padding-bottom: .675rem; }

.group-table.ui.table.basic tbody > tr > td.table__cell--avatar {
  padding-top: 1rem; }

.status--active::before {
  color: #81d135;
  background: #81d135; }

.status--active:after {
  color: #81d135; }

.status--pending::before {
  color: #f3a536;
  background: #f3a536; }

.status--pending:after {
  color: #f3a536; }

.status--inactive::before {
  color: #ce0b24;
  background: #ce0b24; }

.status--inactive:after {
  color: #ce0b24; }

.ui.modal > :first-child:not(.icon),
.ui.modal > .icon:first-child + * {
  border-radius: 0; }

.browser-ie.ui.modal {
  position: static; }
  .browser-ie.ui.modal > .ui.button--close {
    position: relative;
    right: -40.12rem; }

.interstital .form-container {
  width: 20rem;
  margin: 7rem auto 0;
  box-sizing: border-box; }

.authentication__heading {
  margin-bottom: 25px;
  padding-top: 28px;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: 3.9px;
  text-transform: uppercase;
  text-align: center; }

.nexus-logo__ {
  width: 44px;
  margin: 0 auto; }

.nexus-logo__beta {
  margin-top: -8px; }

:root {
  font-family: "Lato"; }

.interstital {
  position: absolute;
  width: 100%;
  min-height: 100%;
  background-image: linear-gradient(125deg, #57134e, #2c0826);
  color: #fff; }
  .interstital a {
    display: inline-block;
    padding-bottom: 0.25rem;
    border-bottom: solid 1px #fff;
    color: #fff;
    font-size: 0.6875rem;
    letter-spacing: 0.1875rem;
    text-transform: uppercase;
    padding-left: 3px; }
