@import "../../../../scss/variables";

.group {
  &-name {
    font-family: Lato;
    font-size: 0.9375rem;
    color: $grey-dark;
    margin-bottom: 0.5rem;
  }

  &-description {
    opacity: 0.5;
    font-size: .625rem;
    font-weight: bold;
    letter-spacing: .125rem;
    color: $grey-dark;
    text-transform: uppercase;
    margin-bottom: .25rem;
  }

  &-sites {
    opacity: .5;
    font-size: .625rem;
    font-weight: bold;
    letter-spacing: .125rem;
    color: $grey-dark;
    text-transform: uppercase;
  }

  &-permit-count {
    width: 7.5rem;
    height: 1.875rem;
    border-radius: .9375rem;
    border: solid .0625rem $secondary-colour;
    text-align: center;
    top: 1.25rem;
    padding-top: .5rem;
    position: relative;
    float: right;
    color: $black;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $black;
    }

    &-number {
      font-weight: bold;
    }
  }
}