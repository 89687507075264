.status--active {
  &::before {
    color: $status--active;
    background: $status--active;
  }
  &:after{
    color: $status--active;
  }
}
.status--pending{

  &::before {
    color: $status--pending;
    background: $status--pending;
  }
  &:after{
    color: $status--pending;
  }
}
.status--inactive{
  &::before {
    color: $status--inactive;
    background: $status--inactive;
  }
  &:after{
    color: $status--inactive;
  }
}

.ui.modal > :first-child:not(.icon),
.ui.modal > .icon:first-child + * {
  border-radius: 0;
}