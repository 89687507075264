#edit-record__status {
  position: absolute;
  top: -.5rem;
  left: .75rem;
  z-index: 99;
  > .text {
    text-indent: -999999px;
  }
  &.status--inactive,
  &.status--pending,
  &.status--active {
    &:after{
      content: '•';
      font-size: 2.5rem;
    }
  }

  i.dropdown{
    float: right;
    margin-top: 1.5rem;
    margin-left: 0.25rem;
  }
}