.SVGIcon {
  box-sizing: content-box;
  margin: 32px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% {  transform: rotate(359deg); }
}

#loading-spinner {
  animation: spin 2s linear infinite;
}