@import '../../scss/variables';

.Navigation {
  position: fixed;
  width: 110px;
  height: 100vh;
  background-image: $background-gradient;
  flex: 0 0 auto;

  .navigation__logo {
    padding: 18px 0;
  }

  .navigation__icon {
    float: left;
    width: 40px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
    li {
      margin: 5px 0;
      svg {
        float: left;
        margin: 15px 10px;
      }
      span {
        font-size:11px;
        float: left;
        line-height: 50px;
        text-align: left;
        &.double-line {
          line-height: 12px;
          margin-top: 14px;
        }
      }
      a {
        display: block;
        float: left;
        width:100%;
        padding: 5px 0;
        color: $white;
        &:hover,
        &.active {
          background: $trans-white-10pc;
          color: $secondary-colour;
          path {
            fill: $secondary-colour;
          }
        }
      }
    }
  }
}
