.logout-button {
  position: fixed;
  bottom: 1.75rem;
  left: 1rem;
  height: 1.75rem;
  width: 4.75rem;
  cursor: pointer;
  color: white;
  background: transparent;
  font-size: .625rem;
  line-height: 1.5rem;
  letter-spacing: .1125rem;
  text-align: center;
  border-radius: .875rem;
  border: solid .0625rem white;
}