
.ui.modal {
    width: 41.25rem;

    > .ui.button--close{
      position: absolute;
      top: 0 - ($button-size--round-large / 2);
      right: 0 - ($button-size--round-large / 2);
      width: $button-size--round-large;
      height: $button-size--round-large;
      background: $grey-very-light;
      border-radius: $button-size--round-large / 2;
    }

    .content{
      padding: 3.5rem 6rem 1.5rem;
    }
}

.\--position-absolute{
  position: absolute;
}
.\--full-width{
  width: 100%;
  clear: both;
}
.\--half-width{
  width: 50%;
}

.\--float-left {
  float: left;
}

.\--spacer {
  min-height: 2rem;
}

aside{
  padding: 0 0 1.5rem;
  font-size: $label-font-size;
  color: $grey-light;
}