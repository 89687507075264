@import './variables';

.ui.table.basic {
  font-family: Lato;
  border: 0;
  margin-bottom: 1rem;

  thead > tr > th {
    border: 0;
    text-transform: uppercase;
    font-family: Lato;
    font-size: .625rem;
    opacity: .5;
    letter-spacing: .1125rem;
    color: $text-primary-colour;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
  }

  tbody {
    tr {
      border: 0;
      td {
        padding: .75rem;
        border: 0;
        border-bottom: solid 2px #e2e2e2;
        font-size: .75rem;

        &.no-bottom-border {
          border-bottom: 0;
        }
        &.larger-font {
          font-size: .875rem;
          line-height: 1rem;
        }

        &.shrunk-to-fit {
          width:1%;
          white-space:nowrap;
        }

        &.table-tools {
          padding-right: 0;
          width: 6.25rem;

          .ui.button {
            margin-bottom: 0;
            top: 1.5rem;
            position: relative;
            float: right;
          }

          a {
            position: relative;
            top: 0.75rem;
            display: block;
            float: right;
            margin: 0 0 0 .125rem;

            &.add-link {
              top: .625rem;
            }
          }
        }

        &.align-middle {
          vertical-align: middle;
        }
      }
    }

    .button--delete{
      position: absolute;
    }
  }
}

.table__top-buttons{
  text-align: right;
  padding-right: 1rem;
}

.status {
  position: absolute;
  width: .625rem;
  height: .625rem;
  border-radius: 50%;

  &.status-active {
    background: $status--active;
  }
  &.status-pending {
    background: $status--pending;
  }
  &.status-inactive, &.status-expired {
    background: $status--inactive;
  }
  &-text {
    margin-left: 1rem;
  }
}

.tabletools--modal-trigger {
  &.ui {
    &.button {
      &.button--icon {
        width: auto;
        float: right;
        margin-right: 0;
        text-transform: uppercase;
        font-size: .75rem;

        ~ .button--icon {
          margin-right: 2rem;
        }

        .add-icon {
          margin-top: 15px;
        }
        
        span {
          margin-top: .75rem;
          margin-left: .75rem;
          display: block;
          float: right;
          font-size: .75rem;
          line-height: 2.55;
          letter-spacing: .09rem;
          color: $black;
        }
      }
    }
  }
}

.group-table{
  &.ui.table.basic{
    tbody {
      > tr {
        > td{
          &.table__cell--details {
            padding-top: 1.5rem;
            padding-bottom: .675rem;
          }

          &.table__cell--avatar {
            padding-top: 1rem;
          }
        }
      }
    }
  }
}