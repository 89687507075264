@import url(https://fonts.googleapis.com/css?family=Lato);

@import './reset';
@import './variables';
@import './layout';
@import './forms';
@import './helpers';
@import './tables';
@import './style';
@import './ie';

/////////////
// Containers
/////////////
@import '../containers/authentication/authentication';

/////////////
// Components
/////////////
@import '../components/logo/logo';

:root{
  font-family : $default-font;
}

.interstital {
  position         : absolute;
  width            : 100%;
  min-height       : 100%;
  background-image : $background-gradient;
  color            : $interstital-text-colour;

  a {
    display        : inline-block;
    padding-bottom : 0.25rem;
    border-bottom  : solid 1px $interstital-text-colour;
    color          : $interstital-text-colour;
    font-size      : $label-font-size;
    letter-spacing : $label-letter-spacing * 1.5;
    text-transform : $link-lable-text-transform;
    padding-left   : 3px;
  }
}
