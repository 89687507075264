@import '../../scss/variables';

.interstital{
  .form-container {
    width: $interstital-width;
    margin: $interstital-top auto 0;
    box-sizing: border-box;
  }
}

.authentication__heading {
  margin-bottom: 25px;
  padding-top: 28px;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: 3.9px;
  text-transform: $link-lable-text-transform;
  text-align: center;
}
