@import '../../scss/variables';

.ui {
  &.pagination {
    &.menu {
      float: right;
      border: 0;
      box-shadow: none;

      a.item {
        border-radius: 50%;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        min-width: auto; //reset semantic
        padding-left: 0px;
        padding-right: 0px;

        &::before {
          display: none;
        }

        &:hover {
          background-color: transparent;
        }

        &.active {
          color: $white;
          background-color: $primary-colour;
        }
      }
    }
  }
}
