@import '../../scss/variables';

.login-container {
  a {
    margin-top : 4rem;
  }
}

.authentication {
  .nexus-logo__ {
    width: 94px;
    margin-bottom: 2rem;

    .nexus-logo__beta {
      width: 72px;
      margin: 5px auto 0 12px;
    }

  }
}

.ui.error.input.login-field {
  margin-bottom: 1.5rem;
  border-radius: 2px;
  border: solid 1px $error-colour;
  #auth-login__username, #auth-login__password {
    &::placeholder {
      color: $white;
    }
  }
}

.login-error {
  margin: -20px 0 14px 0;
  font-family: Lato;
  font-size: 10px;
  line-height: 1.6;
  color: $error-text-colour;
}