@import "../../scss/variables";

.search-container {
  width: 100%;
  height: 100px;
  background: #f2f2f2;
  margin-left: 110px;

  .search-field  {
    display: flex;
    padding: 30px 0 30px 68px;

    svg {
      position: relative;
      top: 10px
    }

    .ui.input {
      margin-bottom: 0;
      input {
        margin-left: 18px;
        border-bottom: 0;
        background: transparent;
        font-family: Lato;
        font-size: 18px;
        font-weight: 300;

        &:focus {
          background: transparent;
        }

        ::placeholder,
        :-ms-input-placeholder ,
        ::-ms-input-placeholder  {
          color:  $grey-very-light;
        }

      }
    }
  }
}



//todo rems