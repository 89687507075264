@mixin label-properties() {
        display: block;
        width: 100%;
        padding: $label-padding;
        background: transparent;
        color: $grey-mid;
        font-size: $label-font-size;
        letter-spacing: $label-letter-spacing;
        text-transform: uppercase;
}

.ui {
  &.form {

    .field {
      &.error {
        margin-bottom: 0;

        .ui {
          &.input,
          &.button {
            margin-bottom: 0;
          }
        }
      }
    }

    .field,
    .field > .selection.dropdown,
    aside {
      &.\--full-width,
      &.\--half-width,
      &.\--quarter-width {
        clear: none;
        margin: $field-margin;
        width: calc(100% - 1rem);
        @extend .\--float-left;
      }
      &.\--half-width {
        width: calc(50% - 1rem);
      }
      &.\--quarter-width {
        width: calc(25% - 1rem);

        .ui.icon.input > input {
          padding-right: 0 !important;
        }
      }

      &.\--large {
        input,
        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='search'],
        input[type='tel'],
        input[type='time'],
        input[type='text'],
        input[type='file'],
        input[type='url'] {
          height: $input-height-large;
          padding: 0 0 0.5rem;
          font-size: $input-font-size-large;
        }
      }

      > label,
      .labeled .label {
        @include label-properties();
      }

      .labeled {
        display: block;
        width: 100%;

        &.error {
          > label,
          .label {
            color: $status--inactive;
            opacity: .5;
          }
        }
      }

      .ui {
        &.input {
          input {
            width: 100%;
          }
        }
      }
    }
  }

  .ui {
    &.input {
      &.\--left-pad-large {
        input,
        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='search'],
        input[type='tel'],
        input[type='time'],
        input[type='text'],
        input[type='file'],
        input[type='url'] {
          padding-left: 2.5rem;
        }
      }
    }
  }

  &.button {
    &.primary.button {
      width: 100%;
      height: $touch-size;
      border: 0;
      border-radius: $touch-size / 2;
      background: $primary-colour;
      font-size: $label-font-size;
      letter-spacing: $label-letter-spacing;
      text-transform: uppercase;
    }

    &.button--icon {
      background: $white;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      border-color: transparent;
      padding: 0;

      > .icon,
      > .icon:not(.button):not(.dropdown) {
        margin: 0 auto;
        color: $primary-colour;
      }
    }
  }

  &.input,
  &.button {
    margin-bottom: $m-bottom;
  }

  &.input {
    input {
      &::placeholder {
        color: $grey-mid;
      }
    }
    input,
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='time'],
    input[type='text'],
    input[type='file'],
    input[type='url'] {
      border: none;
      border-bottom: $input-border-bottom;
      box-shadow: none;
      color: $grey-dark;
      padding: $input-padding;
      border-radius: 0;
      &:focus {
        border-bottom-color: $primary-colour;
      }
    }

    &.\--no-bottom-border {
      input {
        border-bottom: none;
      }
    }
  }
  &.error {
    margin-bottom: 0;

    input,
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="text"],
    input[type="file"],
    input[type="url"] {
      color: $status--inactive;
      border-color: $status--inactive;
    }
  }

  &.selection {
    &.dropdown {
      padding: $input-padding;
      border: none;
      border-bottom: $input-border-bottom;

      &.\--no-border-bottom {
        border-bottom: none;
      }
    }
  }

  &.toggle.checkbox {
    margin: 0.25rem 0;
    overflow: visible;

    input {
      ~ label {
        font-size: $label-font-size;
        letter-spacing: $label-letter-spacing;
        padding-left: 3.25rem;

        color: inherit;
        text-transform: $link-lable-text-transform;

        &:before {
          height: 1rem;
          width: 2.5rem;
          margin: ($touch-size-sm - 1rem) / 2;
          border-radius: 0.5rem;
          background-color: $trans-white-10pc;
        }

        &:after {
          height: 1.25rem;
          width: 1.25rem;
        }
      }

      &:focus,
      &:checked,
      &:focus:checked {
        ~ label {
          &:before {
            background-color: $trans-white-10pc !important;
          }
        }
      }
      &:checked {
        ~ label {
          &:after {
            left: 1.5rem;
          }
        }
      }
    }
  }
}

.authentication.interstital {
  .input,
  input,
  button {
    color: $interstital-text-colour;
  }

  .ui {
    &.input {
      width: 100%;
    }

    input {
      width: 100%;
      background: $input-bg;
      border-bottom: $input-border-bottom--interstital transparent;
      border-radius: $border-radius;
      height: $touch-size;
      padding: $input-border-bottom-width--interstital $lr-padding 0px;
      box-sizing: border-box;
      color: $interstital-text-colour;
      font-size: $input-font-size;

      &:focus {
        background: $input-bg;
        border-color: $secondary-colour;
      }
    }

    &.primary.button {
      background: $secondary-colour;
    }
  }
}

.field__validation-messages{
  li{
    height: 1.5rem;
    color: $status--inactive;
    font-size: .625rem;
    line-height: 1.75rem;
  }
}

label[for="edit-permit__vrm"] {
  @include label-properties();
  float: left;
  margin: $field-margin;
}

.form__vrm-fieldset{
  margin: $field-margin;
  margin-bottom: 1.5rem;
  border: solid $border-width $grey-super-light;
  border-radius: 0.25rem;

  .ui {
    &.input {
      margin-bottom: 0;

      > input{
        text-align: center;
        padding: 1rem 0 0;

        &[name="vrm"] {
          font-size: 1.5rem;
          font-weight: bold;
          letter-spacing: 0.375rem;
        }

        &[name="vrmDescription"] {
          padding-top: 0;
          margin-bottom: 1rem;
          color: $grey-light;
          font-size: .625rem;
        }
      }

    }
  }
}

#edit-group__permitType-field{
  z-index: 99;
  top: 0;
  right: 0;
  margin-top: 0.5rem;
  margin-right: 0;

  .dropdown{
    min-width: calc(25% - 1rem);
  }

}

.ui.selection.dropdown .menu {
  margin-top: .1875rem;
}

$checkbutton-active-colour: $secondary-colour;
$checkbutton-active-colour--disabled: $secondary-colour--disabled;
$checkbutton-inactive-colour--disabled: $grey-super-light;
$checkbutton-inactive-textcolour--disabled: $grey-mid;

.modals {
  .ui.checkbutton {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid $checkbutton-active-colour;
    padding: .22rem .9rem;
    border-radius: 1rem;
    margin: .5rem .5rem 0 0;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    label {
      font-size: 14px;
      padding:2px 5px;

      &::before,
      &::after {
        display: none;
      }
    }


    &.checked {
      border: 1px solid $checkbutton-active-colour;
      background: $checkbutton-active-colour;
      label,
      label:focus {
        color:$white;
      }
    }

  }
}

.checkbutton {
  display: inline-block;
  border: 1px solid $checkbutton-active-colour--disabled;
  border-radius: 1rem;
  margin: .5rem .5rem 0 0;
  padding: .26rem 1rem;
  font-size: 14px;

  &.checkbutton-active {
    border: 1px solid $checkbutton-active-colour--disabled;
    background: $checkbutton-active-colour--disabled;
    color:$white;
  }
  &.checkbutton-inactive {
    border: 1px solid $checkbutton-inactive-colour--disabled;
    background: $checkbutton-inactive-colour--disabled;
    color: $checkbutton-inactive-textcolour--disabled;
  }
}