@import "../../../scss/variables";

.search-results__table {
  .ui.button.button--add,
  .back-arrow {
    display: none;
  }

  table.basic.very.ui.permit-table {
    width: 100%;
    margin-left: 0;
  }

  .search-results__heading {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid $secondary-colour;
    h4 {
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
}

.loading-spinner {
  text-align: center;
  margin-top: 2rem;
}