@import '../../scss/variables';

.back-button {
  font-size: 24px;
  font-weight: 300;
  margin-left: 12px;
  
  a {
    text-decoration: none;
    color: $black;

    &:hover {
      text-decoration: none;
      color: $black;
    }
  }

  .button-text {
    margin-left: 15px;
  }
}