@import '../../scss/variables';

.modal {
  .ui.form {

    label.label-description {
      text-transform: none;
      font-weight: normal;
      letter-spacing: initial;
      font-size: 13px;
      padding-top: 0;
    }
  }
}

.link-button {
  display: flex;
  p {
    line-height: 33px;
    margin-left: 0.3rem;
    color: $black;
  }
}

.edit-user__form {
  .edit-user__field-status {
    position: absolute;
    left: 0;
  }

  &.ui.form {
    .edit-user__field-status {
      z-index: 99;
      width: 55px;

      &.field > .selection.dropdown {
        display: inline;
        .text {
          display: inline;
        }

        > .dropdown.icon {
          right: auto;
        }
      }
    }
  }

  #edit-user__status {
    position: relative;
  }
}

.permit-status-wrapper {
  position: absolute;
  left: 1rem;
  top: 1rem;
  width: 1rem;
  height: 1rem;
  z-index: 999;
  overflow: hidden;
  text-indent: -10rem;

  .status {
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    border: 2px solid $grey-very-light;
    box-sizing: border-box;
  }

  .status-active,
  .status-inactive {
    border: none;
  }
}
